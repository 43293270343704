<template>
  <v-card
    v-if="!loading"
    class="user-edit-info pa-sm-10 pa-3"
  >
    <v-card-title class="justify-start text-h5">
      <span v-if="action === 'create'">
        {{ t('Create New News Article') }}
      </span>
      <span v-else-if="action === 'update'">
        {{ t('Edit News Article Information') }}
      </span>
      <span v-else>
        {{ t('News Article Information') }}
      </span>
    </v-card-title>

    <v-card-text class="mt-5">
      <v-form
        ref="form"
        class="multi-col-validation"
        @submit.prevent="onSubmit"
      >
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="data.title"
              name="title"
              :label="t('Title')"
              counter="250"
              maxlength="250"
              :rules="[validators.required]"
              :error-messages="errorMessages.title"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="data.subtitle"
              name="subtitle"
              :label="t('Subtitle')"
              counter="250"
              maxlength="250"
              :rules="[]"
              :error-messages="errorMessages.subtitle"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="data.slug"
              name="slug"
              counter="200"
              maxlength="200"
              autocomplete="off"
              :rules="[validators.required]"
              :error-messages="errorMessages.slug"
            >
              <template v-slot:label>
                <div>
                  {{ t('Slug') }}&nbsp;
                  <small>({{ t('Editable autogenerated unique value!') }})</small>
                </div>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12">
            <date-time-picker
              v-model="data.date"
              :date-time="data.date"
              dense
              show-current
              input-name="date"
              :rules="[validators.required]"
              :input-label="t('Published At')"
              :error-messages="errorMessages.date"
              class="pt-3 pb-4 mt-1"
              @update:dateTime="val => data.date = val"
            />
          </v-col>

          <v-col cols="12">
            <v-select
              v-model="data.categories"
              :items="newsCategories"
              name="categories"
              :label="t('Categories')"
              dense
              multiple
              clearable
              chips
              small-chips
              deletable-chips
              full-width
              show-label
              single-line
              item-text="name"
              item-value="id"
              return-object
              :rules="[validators.required]"
              :menu-props="{ closeOnClick: true }"
              :error-messages="errorMessages.categories"
              class="position-relative pt-3 mt-1"
            >
              <template #prepend-item>
                <div class="mb-2">
                  <div class="px-3 mb-1">
                    <label
                      for="categories"
                      class="v-label v-label--active text-sm font-weight-medium"
                      :class="{
                        'theme--dark': $vuetify.theme.isDark,
                        'theme--light': !$vuetify.theme.isDark,
                      }"
                    >
                      {{ t('Categories') }}
                    </label>
                  </div>
                  <v-divider />
                </div>
              </template>
              <template
                v-if="data.categories && data.categories.length"
                #prepend-inner
              >
                <label
                  for="categories"
                  class="v-label v-label--active position-absolute"
                  :class="{
                    'theme--dark': $vuetify.theme.isDark,
                    'theme--light': !$vuetify.theme.isDark,
                  }"
                  :style="{
                    top: '-2px',
                    left: 0,
                    right: 'auto',
                    transformOrigin: 'top left'
                  }"
                >
                  {{ t('Categories') }}
                </label>
              </template>
            </v-select>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="data.url"
              name="url"
              autocomplete="off"
              :label="t('URL')"
              :rules="[validators.urlValidator]"
              :error-messages="errorMessages.url"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="data.vimeoId"
              name="vimeo_id"
              autocomplete="off"
              :label="t('Vimeo Video ID')"
              :rules="[validators.integerValidator]"
              :error-messages="errorMessages.vimeo_id"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-file-pond
              :controller.sync="pondController.headerImage"
              name="image"
              disk="news"
              max-files="1"
              class="w-100"
              :label="t('Header Image')"
              max-paralel-uploads="1"
              :allow-multiple="false"
              :errors="errorMessages.headerImage"
            />
          </v-col>

          <v-col cols="12">
            <v-file-pond
              :allow-multiple="true"
              :controller.sync="pondController.mediaImages"
              name="media_image"
              disk="media_images"
              class="w-100 v-filepond-container--grid"
              :label="t('Swipeable Images')"
              max-paralel-uploads="3"
              image-preview-height="250"
              :errors="errorMessages.mediaImages"
            />
          </v-col>

          <v-col cols="12">
            <div class="v-text-field py-3">
              <element-tiptap
                ref="elTipTap"
                :value="data.content"
                min-height="350"
                name="content"
                :label="t('Content')"
                class="position-relative"
                editor-content-class="editor-content-class"
                output-format="html"
                :placeholder="t('Enter some text...')"
                :error-messages="errorMessages.content"
                @input="onContentUpdate"
              >
                <template #toolbar-before>
                  <label
                    for="content"
                    class="v-label v-label--active theme--dark"
                    style="top:0; left: 0; right: auto; position: absolute; transform-origin: top left;"
                  >
                    {{ t('Content') }}
                  </label>
                </template>
              </element-tiptap>

              <v-textarea
                v-show="false"
                v-model="data.content"
                name="content"
                :label="t('Content')"
                :error-messages="errorMessages.content"
              ></v-textarea>
            </div>
          </v-col>

          <v-col cols="12">
            <call-to-actions
              :items="data.callToActions"
              :error-messages="callToActionsErrorMessages"
              class="mb-6"
            />
          </v-col>

          <v-col cols="12">
            <v-radio-group
              v-model="data.visibility"
              name="visibility"
              :rules="[validators.numericSafeRequired]"
              :error-messages="errorMessages.visibility"
            >
              <template v-slot:label>
                <label
                  class="v-label v-label--active"
                  :style="{
                    maxWidth: '133%',
                    transform: 'translateY(-18px) scale(0.75)',
                    pointerEvents: 'auto',
                    position: 'absolute',
                    right: 'auto',
                    top: 0,
                    transformOrigin: 'top left',
                  }"
                >
                  {{ t('Visibility') }}
                </label>
              </template>
              <v-radio
                :value="1"
                color="primary"
              >
                <template v-slot:label>
                  {{ t('Visible') }}
                </template>
              </v-radio>
              <v-radio
                :value="0"
                color="error"
              >
                <template v-slot:label>
                  {{ t('Hidden') }}
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>

          <v-col
            cols="12"
            class="d-flex justify-start mt-3"
          >
            <v-btn
              color="primary"
              class="me-3"
              type="submit"
              :disabled="updating || pondController.uploading"
              :loading="updating || pondController.uploading"
            >
              {{ t('Submit') }}
            </v-btn>

            <v-btn
              v-if="resetForm"
              outlined
              color="secondary"
              class="me-3"
              :disabled="updating || pondController.uploading"
              :loading="updating || pondController.uploading"
              @click="resetForm"
            >
              {{ t('Discard') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref, computed, onMounted, watch, nextTick, reactive } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'

// eslint-disable-next-line object-curly-newline, no-unused-vars
import { mdiPlus, mdiPencil, mdiUpdate, mdiEyeOutline, mdiDeleteOutline } from '@mdi/js'

// eslint-disable-next-line object-curly-newline, no-unused-vars
import { required, emailValidator, urlValidator, integerValidator, numericSafeRequired } from '@core/utils/validation'

import useNewsCategoryList from '@views/newsCategories/news-category-list/useNewsCategoryList'
import { useCoreUtils } from '@/@core/utils/utils'
import DateTimePicker from '@components/DateTimePicker.vue'
import ElementTiptap from '@components/ElementTiptap.vue'
import CallToActions from '@views/news/news-view/call-to-actions/CallToActions.vue'
import VFilePond from '@components/VFilePond.vue'

export default {
  name: 'NewsForm',
  components: {
    CallToActions,
    DateTimePicker,
    ElementTiptap,
    VFilePond,
  },

  emits: ['generate:slug'],

  props: {
    action: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },

    loading: {
      type: Boolean,
      default: false,
    },
    updating: {
      type: Boolean,
      default: false,
    },

    handleSubmit: {
      type: Function,
      required: true,
      default: () => {},
    },
    handleDiscard: {
      type: Function,
      default: null,
    },
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const { t } = useUtils()
    const { slugify } = useCoreUtils()

    const elTipTap = ref(null)

    const form = ref(null)

    const isCodeViewMode = computed(() => elTipTap.value?.$refs?.editorRef?.isCodeViewMode)

    // const dateMenu = ref(false)
    // const datePicker = ref(props.data.date)

    // Is pond uploading
    const pondController = reactive({
      headerImage: reactive({
        files: [],
        uploading: false,
      }),
      mediaImages: reactive({
        files: [],
        uploading: false,
      }),

      uploading: computed(() => pondController.headerImage.uploading || pondController.mediaImages.uploading),
    })

    onMounted(() => {
      if (props.data.image) {
        pondController.headerImage.files = [{
          source: props.data.image,
          options: {
            type: 'local',
          },
        }]
      }

      if (Array.from(props.data?.images ?? []).length) {
        pondController.mediaImages.files = (Array.from(props.data?.images ?? [])
          .map(img => ({
            source: img,
            options: {
              type: 'local',
            },
          }))
        )
      }
    })

    const {
      fetchItems,
      newsCategories,
    } = useNewsCategoryList()

    const onSubmit = () => {
      const isFormValid = form.value.validate()
      if (!isFormValid) return

      // eslint-disable-next-line no-unreachable
      if (isCodeViewMode.value) {
        elTipTap.value.$refs.editorRef.isCodeViewMode = false

        nextTick(onSubmit)

        return
      }

      const formData = new FormData(form.value.$el)

      const mediaImages = formData.getAll('media_image')
      formData.delete('media_image')
      mediaImages.forEach(mediaImg => formData.append('media_image[]', mediaImg))

      // console.log([...formData.entries()])

      // return

      // eslint-disable-next-line no-unreachable
      formData.delete('categories')
      props.data.categories.forEach((cat, i) => formData.append(`categories[${i}]`, cat.id))

      // formData.append('content', props.data.content)
      // formData.append('categories[]', category.id)

      props.handleSubmit(formData)
    }

    const resetForm = () => {
      form.value.reset()
      form.value.resetErrorBag()
      form.value.resetValidation()

      // errorMessages.value = []

      props.handleDiscard()
    }

    const onContentUpdate = val => {
      // eslint-disable-next-line no-param-reassign
      props.data = {
        ...props.data,
        content: val ?? '',
      }
    }

    const onActionsUpdate = items => {
      // eslint-disable-next-line no-param-reassign
      props.data = {
        ...props.data,
        callToActions: items,
      }
    }

    onMounted(() => {
      fetchItems()
    })

    watch(() => props.data.title, newVal => {
      emit('generate:slug', slugify(newVal))
    })

    const callToActionsErrorMessages = computed(() => {
      const [k, err] = Array.from(Object.entries(props.errorMessages).find(([key]) => key.startsWith('call_to_actions.')) ?? [])
      if (err === undefined) return {}

      // eslint-disable-next-line no-unused-vars
      const [_, i, prop] = k.split('.')

      const errors = []

      errors[i - 1] = {
        [prop]: err,
      }

      return errors
    })

    return {
      t,

      icons: {
        mdiPlus,
        mdiPencil,
        mdiUpdate,
        mdiEyeOutline,
        mdiDeleteOutline,
      },

      validators: {
        required,
        urlValidator,
        emailValidator,
        integerValidator,
        numericSafeRequired,
      },

      form,
      onSubmit,
      resetForm,

      // dateMenu,
      // datePicker,

      pondController,

      newsCategories,

      elTipTap,

      onContentUpdate,
      onActionsUpdate,

      callToActionsErrorMessages,

      log: e => console.log(e),
    }
  },
}
</script>

<style scoped>
::v-deep .tiptap-vuetify-editor__content {
  min-height: 350px;
}

::v-deep .ProseMirror {
  min-height: 300px;
}
</style>
