<script>
import { useUtils } from '@core/libs/i18n'
import {
  computed,
  nextTick,
  onMounted,
  reactive,
  ref,
  watch,
} from '@vue/composition-api'
import { required, urlValidator } from '@core/utils/validation'
import { mdiDeleteOutline, mdiPencil, mdiPlus } from '@mdi/js'

export default {
  name: 'CallToActions',

  emits: ['items:sorted'],

  props: {
    items: {
      type: Array,
      default: () => ([]),
    },
    errorMessages: {
      type: Array,
      default: () => ([]),
    },
  },

  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const { t } = useUtils()

    const headers = ref([
      // eslint-disable-next-line object-curly-newline
      { text: t('Title'), value: 'title', align: 'start', sortable: false, width: '35%' },

      // eslint-disable-next-line object-curly-newline
      { text: t('Link'), value: 'link', align: 'start', sortable: false, width: '55%' },

      // eslint-disable-next-line object-curly-newline
      { text: t('Actions'), value: 'actions', align: 'end', sortable: false, width: '10%' },
    ])

    const dialog = ref(false)
    const dialogDelete = ref(false)

    const editedIndex = ref(-1)
    const editedItem = reactive({
      title: null,
      link: null,
    })

    const defaultItem = reactive({
      title: null,
      link: null,
    })

    const form = ref(null)
    const table = ref(null)

    const actions = computed({
      get() {
        return props.items
      },
      set(value) {
        emit('update:items', value)
      },
    })

    const title = computed(() => editedIndex.value === -1 ? t('Add New Call To Action') : t('Edit Call To Action'))

    const selectItem = item => {
      editedIndex.value = actions.value.indexOf(item)

      editedItem.title = item.title
      editedItem.link = item.link
    }

    // eslint-disable-next-line no-unused-vars
    const deselectItem = () => {
      editedIndex.value = -1

      editedItem.title = defaultItem.title
      editedItem.link = defaultItem.link
    }

    const close = () => {
      dialog.value = false

      // nextTick(deselectItem)
    }
    const closeDelete = () => {
      dialogDelete.value = false

      // nextTick(deselectItem)
    }

    const editItem = item => {
      selectItem(item)
      dialog.value = true
    }

    const deleteItem = item => {
      selectItem(item)
      dialogDelete.value = true
    }

    const deleteItemConfirm = () => {
      actions.value.splice(editedIndex.value, 1)
      closeDelete()
    }

    const save = () => {
      const isFormValid = form.value.validate()
      if (!isFormValid) return

      if (editedIndex.value > -1) {
        Object.assign(actions.value[editedIndex.value], { ...editedItem })
      } else {
        actions.value.push({ ...editedItem })
      }

      close()
    }

    const init = () => {
      actions.value = props.items
    }

    watch(dialog, newVal => newVal || close())
    watch(dialogDelete, newVal => newVal || closeDelete())

    watch(() => props.items, () => nextTick(init))

    onMounted(() => {
      init()
    })

    const handleOrder = e => {
      e.preventDefault()
      e.stopPropagation()

      const clonedActions = Array.from(actions.value)

      clonedActions.splice(e.newIndex, 0, clonedActions.splice(e.oldIndex, 1)[0])

      nextTick(() => {
        actions.value = clonedActions
      })
    }

    const indexedErrors = index => Object.entries(props.errorMessages[index] ?? {})

    return {
      t,

      actions,

      form,
      table,

      dialog,
      dialogDelete,

      editedIndex,
      editedItem,

      headers,

      icons: {
        mdiDeleteOutline,
        mdiPencil,
        mdiPlus,
      },

      title,

      validators: {
        required,
        urlValidator,
      },

      cb: {
        close,
        closeDelete,

        deleteItem,
        deleteItemConfirm,

        editItem,

        handleOrder,

        init,
        save,

        indexedErrors,
      },
    }
  },
}
</script>

<template>
  <v-data-table
    ref="table"
    v-sortable-data-table
    class="elevation-4"
    :footer-props="{
      disablePagination: true,
      disableItemPerPage: true,
      itemsPerPage: [-1],
    }"
    :headers="headers"
    hide-default-footer
    :items="actions"
    :items-per-page="-1"
    @sorted="cb.handleOrder"
  >
    <template v-slot:top>
      <v-toolbar
        color="rgba(0, 0, 0, .15)"
        dark
        dense
      >
        <v-toolbar-title class="text-caption">
          {{ t('Call To Actions') }}
        </v-toolbar-title>

        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>

        <v-spacer></v-spacer>

        <v-dialog
          v-model="dialog"
          max-width="650px"
        >
          <template v-slot:activator="{ on: activatorOn, attrs: activatorAttrs }">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  class="self-center"
                  v-bind="{
                    ...activatorAttrs,
                    ...attrs,
                  }"
                  fab
                  x-small
                  elevation="4"
                  color="primary"
                  v-on="{
                    ...activatorOn,
                    ...on,
                  }"
                >
                  <v-icon>
                    {{ icons.mdiPlus }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Add New Call To Action</span>
            </v-tooltip>
          </template>

          <v-card>
            <v-form
              ref="form"
              @submit.prevent="cb.save"
            >
              <v-card-title>
                <span class="text-h5">
                  {{ title }}
                </span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editedItem.title"
                        autofocus
                        counter="250"
                        :label="t('Title')"
                        maxlength="250"
                        name="title"
                        :rules="[validators.required]"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        v-model="editedItem.link"
                        counter="250"
                        :label="t('Link')"
                        maxlength="250"
                        name="link"
                        :rules="[validators.required, validators.urlValidator]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="secondary"
                  class="me-3"
                  outlined
                  @click="cb.close"
                >
                  {{ t('Discard') }}
                </v-btn>
                <v-btn
                  color="primary"
                  type="submit"
                >
                  {{ t('Save') }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="dialogDelete"
          max-width="650px"
        >
          <v-card class="user-edit-info pa-sm-10 pa-3">
            <v-card-title class="justify-center text-h5 mb-5">
              <span>{{ t('Delete Call To Action') }}</span>
              <span>&nbsp;</span>
              <span class="error--text">{{ editedItem.title }}</span>
              <span>?</span>
            </v-card-title>

            <v-card-text class="text-center mt-n2">
              {{ t('Are you sure you wish to delete the selected call to action?') }}
            </v-card-text>

            <v-card-text class="text-center mt-n2">
              {{ t('This action cannot be undone!') }}
            </v-card-text>

            <v-card-text class="mt-5">
              <v-col
                cols="12"
                class="d-flex justify-center mt-3"
              >
                <v-btn
                  color="secondary"
                  class="me-3"
                  outlined
                  @click="cb.closeDelete"
                >
                  {{ t('Discard') }}
                </v-btn>

                <v-btn
                  color="error"
                  @click="cb.deleteItemConfirm"
                >
                  {{ t('Delete') }}
                </v-btn>
              </v-col>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template #item="{ item, index }">
      <tr :key="item">
        <td class="text-start">
          <div>
            <div
              :class="{
                'error--text': errorMessages[index]!== undefined && errorMessages[index].title !== undefined
              }"
            >
              {{ item.title }}
            </div>
            <input
              v-show="false"
              :name="`call_to_actions[${(index + 1)}][title]`"
              readonly
              :value="item.title"
            />
          </div>
          <template
            v-for="[k, msg] in cb.indexedErrors(index)"
          >
            <template v-if="k === 'title'">
              <div
                v-for="m in msg"
                :key="`err-${index}-${k}-${m}`"
              >
                {{ m }}
              </div>
            </template>
          </template>
        </td>
        <td class="text-start">
          <div class="border border-b-sm border-error">
            <div>
              <a
                :class="{
                  'error--text': errorMessages[index]!== undefined && errorMessages[index].link !== undefined
                }"
                :href="item.link"
                target="_blank"
              >
                {{ item.link }}
              </a>
            </div>
            <input
              v-show="false"
              :name="`call_to_actions[${(index + 1)}][link]`"
              readonly
              :value="item.link"
              :error-messages="errorMessages"
            />
          </div>
          <template
            v-for="[k, msg] in cb.indexedErrors(index)"
          >
            <template v-if="k === 'link'">
              <div
                v-for="m in msg"
                :key="`err-${index}-${k}-${m}`"
              >
                {{ m }}
              </div>
            </template>
          </template>
        </td>
        <td class="text-end">
          <div>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  class="mr-2"
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="cb.editItem(item)"
                >
                  {{ icons.mdiPencil }}
                </v-icon>
              </template>

              <span>
                {{ t('Edit Call To Action') }}
              </span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  color="error"
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="cb.deleteItem(item)"
                >
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
              </template>

              <span>
                {{ t('Delete Call To Action') }}
              </span>
            </v-tooltip>
          </div>
        </td>
      </tr>
    </template>

    <template v-slot:no-data>
      <div class="text-subtitle-2">
        {{ t('No records found!') }}
      </div>
    </template>

    <template
      v-if="items.length === 0"
      v-slot:body.append
    >
      <tr>
        <td
          class="text-center"
          :colspan="headers.length"
        >
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                x-small
                elevation="4"
                color="primary"
                v-on="on"
                @click="() => dialog = true"
              >
                <v-icon>
                  {{ icons.mdiPlus }}
                </v-icon>
              </v-btn>
            </template>

            <span>Add New Call To Action</span>
          </v-tooltip>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<style lang="scss">
tr.sortable-chosen.sortable-fallback.sortable-drag {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 35% 55% 10%;

  td {
    vertical-align: middle;
    align-content: center;
    align-self: center;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;

    &.text-start {
      justify-content: flex-start;
      text-align: start;
    }
    &.text-end {
      justify-content: flex-end;
      text-align: end;
    }
  }
}

.has-error {
  border-bottom: 1px solid var(--v-error-base);
}
</style>
